import * as React from "react"
import { styled } from "@mui/material/styles"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector"
import { Box, StepIconProps } from "@mui/material"
import { makeStyles } from "@material-ui/core"
import { steps } from "./vertical-intro-progress"

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "rgba(255, 255, 255, 0.5)"
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "rgba(255, 255, 255, 0.5)"
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 20,
  height: 20,
  marginTop: '12px',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: "#0EDCB4",
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: "#0EDCB4"
  }),
}));

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#0EDCB4', // theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '24px',
    marginBottom: '20px'
  },
  description: {
    color: '#FFFFFF',
    fontSize: '18px'
  },
  dotText: {
    fontSize: '80px',
    color: 'rgba(200, 200, 200, 0.2)',
    fontWeight: 700
  }
}))

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useStyles();
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <div className={classes.dotText}>01.</div>,
    2: <div className={classes.dotText}>02.</div>,
    3: <div className={classes.dotText}>03.</div>,
    4: <div className={classes.dotText}>04.</div>,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const IntroProgress = () => {
  const classes = useStyles();
  return <Stepper alternativeLabel activeStep={4} connector={<ColorlibConnector />}>
    {steps.map((step) => (
      <Step key={step.title}>
        <StepLabel StepIconComponent={ColorlibStepIcon}>
          <Box sx={{
            backgroundColor: 'rgba(200, 200, 200, 0.2)',
            width: '210px',
            height: '260px',
            margin: 'auto',
            padding: '40px 15px',
            borderRadius: '25px',
            marginTop: '50px'
          }}>
            <Box className={classes.title}>{step.title}</Box>
            <Box className={classes.description}>{step.description}</Box>
          </Box>
        </StepLabel>
      </Step>
    ))}
  </Stepper>
}

export default IntroProgress
