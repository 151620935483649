import * as React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  svg: {
    background: `url("/images/rolunk_bg.jpg") no-repeat center center`,
    backgroundSize: "cover",
    width: "100vw",
    display: "block",
    height: "100%"
  },
  divSvg: {
    height: 500,
    overflow: "hidden",
    backgroundColor: "#ECF2F5",
    zIndex: 1,
    width: "100%",
    gridColumn: "1 / 1",
    gridRow: "1 / 1"
  },
  onTop: {
    zIndex: 2,
    gridColumn: "1 / 1",
    gridRow: "1 / 1",
    display: "flex",
    width: "100%"
  },
  onTopContent: {
    width: "50%",
    color: "white",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  onTopTitle: {
    fontWeight: "bold",
    paddingBottom: 15
  },
  onTopDescription: {
    fontSize: 14,
    paddingBottom: 15
  },
  divImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  divAlpha: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 48,
    fontWeight: "bold"
  },
  divTexts: {
    width: "70%"
  },
  divTopBottom: {
    color: "black",
    width: "100%",
    padding: 25,
    backgroundColor: "#ECF2F5",
    marginTop: '-30px'
  },
  content: {
    maxWidth: 1200,
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down(1100)]: {
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center"
    }
  },
  divTitle: {
    fontWeight: 400,
    fontSize: 32,
    marginBottom: 30,
    marginTop: 0
  },
  divSmallTitle: {
    fontSize: 18,
    marginBottom: 25
  },
  divDescription: {
    fontSize: 14,
    marginBottom: 15
  },
  img: {
    width: 70,
    height: 22,
    marginBottom: 0
  },
  divInfos: {
    width: "40%",
    [theme.breakpoints.down(1100)]: {
      width: "80%"
    }
  },
  divMonitor: {
    width: "40%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    [theme.breakpoints.down(1100)]: {
      width: "80%",
      padding: '0px 15px'
    }
  },
  picture: {
    width: "100%",
    objectFit: "cover",
    borderRadius: 10
  }
}))

const IntroTopInfos = () => {
  const classes = useStyles()

  return <>
    <div className={classes.divTopBottom}>
      <div className={classes.content}>
        <div className={classes.divMonitor}>
          <img src="/images/alpha_about_us.svg" height={150} alt={"AlphaWS"} style={{minWidth: '300px', marginBottom: 0}}/>
        </div>
        <div className={classes.divInfos}>
          <h2 className={classes.divTitle}>Szoftverfejlesztés a világ megváltoztatóinak</h2>
          <div className={classes.divSmallTitle}>Ismerjen meg minket, és azt amit képviselünk!</div>
          <div className={classes.divDescription}>
            Olyan digitális termékeket fejlesztünk, amik lehetővé teszik, hogy az emberek másképp csinálják a dolgokat.
            Ossza meg velünk a kihívását és együtt dolgozzuk ki a forradalmi digitális termék létrehozását. Ügyfeleink
            megváltoztatták az ügyintézést, az online értékesítést, a bevásárlást és a digitális kölcsönzést.
          </div>
          <div>
            <img src="/images/xo_icons_2.png" className={classes.img} alt={"xo"} />
          </div>
        </div>
      </div>
    </div>
  </>
}

export default IntroTopInfos
