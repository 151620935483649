import * as React from "react"
import Layout from "../components/layout"
import IntroTop from "../components/introduction/intro-top"
import SkillsSlider from "../components/skills-slider/skills-slider"
import MetaData from "../components/metadata"
import StructuredData from "../components/structured-data"
import IntroMiddle from "../components/introduction/intro-middle"
import IntroBottom from "../components/introduction/intro-bottom"

const BemutatkozasPage = (props) => {
  return <Layout subHeader={true}>
    <MetaData title={'Rólunk, kik vagyunk mi?'} url={props.location.href} description={"Sok éves tapasztalattal rendelkező dinamikus csapatként nyitottak vagyunk a legújabb technológiákra és új kihívásokra."}/>
    <StructuredData title={'Rólunk, kik vagyunk mi?'} url={props.location.href} description={"Sok éves tapasztalattal rendelkező dinamikus csapatként nyitottak vagyunk a legújabb technológiákra és új kihívásokra."}/>
    <IntroTop />
    <SkillsSlider />
    <IntroMiddle/>
    <IntroBottom/>
  </Layout>
}

export default BemutatkozasPage
