import * as React from "react"
import TeamMember from "./team-member"
import { Grid } from "@material-ui/core"

const Team = () => {
  return <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={4} lg={3} >
      <TeamMember name={"Kristóf"} position={"projektmenedzser"} pic={"/images/kristof.png"} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3} >
      <TeamMember name={"Zsolt"} position={"vezető fejlesztő"} pic={"/images/oszi.png"} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3} >
      <TeamMember name={"Dávid"} position={"backend fejlesztő"} pic={"/images/david.png"} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3} >
      <TeamMember name={"Roland"} position={"applikáció fejlesztő"} pic={"/images/roland.png"} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3} >
      <TeamMember name={"Áron"} position={"frontend fejlesztő"} pic={"/images/aron.png"} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3} >
      <TeamMember name={"Kornél"} position={"web designer"} pic={"/images/kori.png"} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3} >
      <TeamMember name={"Attila"} position={"értékesítési vezető"} pic={"/images/attila.png"} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={3} >
      <TeamMember name={"Ön"} position={"ügyfél"} pic={"/images/you_avatar.png"} />
    </Grid>
  </Grid>
}

export default Team
