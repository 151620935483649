import * as React from "react"
import IntroTopWave from "../waves/intro-top-wave"
import IntroTopInfos from "./intro-top-infos"

const IntroTop = () => {
  return <div>
    <IntroTopWave bg="/images/rolunk_bg.png" />
    <IntroTopInfos />
  </div>
}

export default IntroTop
