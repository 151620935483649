import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core"
import { StepIconProps } from "@mui/material"
import { styled } from "@mui/material/styles"

export const steps = [
  {
    title: "Terv kialakítás",
    description: "Első lépésben segítünk gyorsan tervet készíteni arról, hogy mire lesz szüksége",
  },{
    title: "Sprint 0",
    description: "A környezet és \n" +
      "a folyamatok kialakítása, a projekt alapjainak lefektetése",
  },{
    title: "Iteráció",
    description: "A végeredményig a fejlesztésre  összpontosítunk \n" +
      "és a felmerülő kéréseken kezdünk dolgozni",
  },{
    title: "Élesítés",
    description: "Elérhetővé tesszük mindenki számára a gyors, biztonságos és az üzleti céljaira szabott fejlesztést",
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#0EDCB4', // theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '24px',
    marginTop: '12px'
  },
  description: {
    color: '#FFFFFF',
    fontSize: '18px',
    marginBottom: '10px'
  },
  dotText: {
    fontSize: '48px',
    color: 'rgba(200, 200, 200, 0.2)',
    fontWeight: 700
  }
}))

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 20,
  height: 20,
  marginTop: '12px',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: "#0EDCB4",
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: "#0EDCB4"
  }),
}));


function ColorlibStepIcon(props: StepIconProps) {
  const classes = useStyles();
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <div className={classes.dotText}/>,
    2: <div className={classes.dotText}/>,
    3: <div className={classes.dotText}/>,
    4: <div className={classes.dotText}/>,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export default function VerticalIntroProgress() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  return (
    <Box sx={{ maxWidth: 400, margin: 'auto' }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.title}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <Box className={classes.title}>{step.title}</Box>
            </StepLabel>
            <StepContent>
              <Box className={classes.description}>{step.description}</Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  {index !== steps.length - 1 && <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    style={{
                      backgroundColor: '#0EDCB4',
                      color: '#FFF',
                      borderRadius: 25,
                      fontWeight: 700,
                      padding: '5px 25px'
                    }}
                  >
                    Tovább
                  </Button>}
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                    style={index !== steps.length - 1 ? {
                      color: '#fff',
                      borderRadius: 25,
                      padding: '5px 25px'
                    } : {
                      backgroundColor: '#0EDCB4',
                      color: '#FFF',
                      borderRadius: 25,
                      fontWeight: 700,
                      padding: '5px 25px'
                    }}
                  >
                    Vissza
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>

    </Box>
  );
}