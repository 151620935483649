import * as React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  div: {
    background: "rgba(200, 200, 200, 0.2)",
    height: 'auto',
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 25,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textAlign: 'center',
    [theme.breakpoints.down(600)]: {
      flexDirection: "row",
    },
    [theme.breakpoints.down(420)]: {
      flexDirection: "column",
    }
  },
  divMemberInfos: {
    width: "100%",
    [theme.breakpoints.down(600)]: {
      width: "70%",
    },
    [theme.breakpoints.down(500)]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      height: 90
    },
    [theme.breakpoints.down(420)]: {
      width: "100%",
    },
  },
  divName: {
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: '3px',
    marginTop: '3px',
  },
  divPosition: {
    fontWeight: "bold",
    fontSize: 14
  },
  divImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 140,
    width: 140,
    marginBottom: '10px'
  },
  img: {
    width: 140,
    height: 140,
    marginBottom: 0,
  }
}))

const TeamMemberCard = ({ name, position, pic}) => {
  const classes = useStyles()

  return <div className={classes.div}>
    <div className={classes.divImg}>
      <img src={pic} className={classes.img} alt={pic}/>
    </div>
    <div className={classes.divMemberInfos}>
      <div className={classes.divName}>{name}</div>
      <div className={classes.divPosition}>{position}</div>
    </div>
  </div>
}

export default TeamMemberCard
