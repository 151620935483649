import * as React from "react"
import { Box, makeStyles, Typography } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  boxAll: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(960)]: {
      width: "95%",
      margin: 'auto',
      flexDirection: 'column',
      justifyContent: 'center',
    }
  },
  boxImg: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(960)]: {
      width: '100%'
    }
  },
  boxInfo: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down(960)]: {
      width: '100%'
    }
  },
  boxTitle: {
    fontSize: "36px",
    lineHeight: "37px",
    marginBottom: "25px",
    [theme.breakpoints.down(960)]: {
      marginTop: '20px'
    }
  },
  primaryText: {
    color: theme.palette.primary.main
  },
  description: {
    fontSize: "18px"
  },
  review: {
    color: "#808080",
    fontSize: "16px",
    maxWidth: "450px",
    marginRight: "auto",
    marginTop: '5px'
  },
  reviewer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(200, 200, 200, 0.2)",
    borderRadius: "25px",
    padding: "20px",
    width: 'fit-content',
  },
  boxFace: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 20,
    minWidth: '50px'
  }
}))

const IntroReview = () => {
  const classes = useStyles()
  return <Box className={classes.boxAll}>
    <Box className={classes.boxImg}><img src="/images/review.png" alt={"review"} /></Box>
    <Box className={classes.boxInfo}>
      <Box className={classes.boxTitle}>
        <span className={classes.primaryText}>Az elmúlt két évtizedben </span>
        segítettünk ügyfeleinknek több platformra eljutni
      </Box>
      <Box className={classes.description}>
        A digitális szolgáltatások teljes skáláját kínáljuk a kezdeti ötlettől és a stratégia megfogalmazásától a
        prototípus elkészítésén és a felhasználók tesztelésén át egészen a megalkotásig.
      </Box>
      <Box className={classes.review}>
        <Box style={{ height: "50px" }}><Typography color={"primary"} style={{ fontSize: "72px" }}>”</Typography></Box>
        <Box style={{ padding: "0px 15px" }}>A legjobb csapat, akikkel eddig együtt dolgoztunk.
          Jól ismerik a termékünket, és képesek új funkciókat hozzáadni, nagy hangsúlyt fektetve a gyorsaságra.
        </Box>
        <Box style={{ textAlign: "right", marginTop: "-65px" }}><Typography color={"primary"}
                                                                            style={{ fontSize: "72px" }}>,,</Typography></Box>
      </Box>
      <Box style={{width: '100%'}}>
        <Box className={classes.reviewer}>
          <Box className={classes.boxFace}><img src="/images/reviewer.png" width={50} height={70} alt={"review"} style={{marginBottom: 0}}/></Box>
          <Box>
            <Typography style={{ fontSize: "18px", fontWeight: 700 }}>Benedek</Typography>
            <Typography style={{ fontSize: "14px", fontWeight: 400 }}>Az Asztalra.hu innovációs igazgatója</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
}

export default IntroReview
