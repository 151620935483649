import * as React from "react"
import { Box, Container, makeStyles } from "@material-ui/core"
import IntroReview from "./intro-review"
import TwoColumnInfo from "../two-column-info/two-column-info"
import TwoColumnTitle from "../two-column-info/two-column-title"
import TwoColumnText from "../two-column-info/two-column-text"
import Team from "../team/team"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url("/images/wave_bg_6.jpg")`,
    transition: "background 5s linear",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 25
  },
  content: {
    padding: "50px 0px",
  }
}))

const IntroMiddle = () => {
  const classes = useStyles()

  return <div className={classes.root}>
    <Container className={classes.content}>
      <IntroReview />
      <Box style={{height: '40px', width: '100%'}}/>
      <TwoColumnInfo
        leftColContent={<TwoColumnTitle firstPart={"Az emberek"}
                                        secondPart={" a siker kulcsai"}
                                        isSecondPartPrimaryColor />}
        rightColContent={<TwoColumnText
          content={<span>
            A technológia önmagában nem elég a sikerhez, minden fejlesztéshez szükség van egy szenvedélyes  csapatra, akik a legjobbat nyújtják Önnek.
            <br />
            <br />
            A mi csapatunk tagjai az Ön partnerei, mert a kód minden sora egy nagyobb történet része.
          </span>} />}
        backgroundColor={"rgba(200, 200, 200, 0.2)"}
      />
      <Box style={{height: '40px', width: '100%'}}/>
      <Team/>
    </Container>
  </div>
}

export default IntroMiddle
