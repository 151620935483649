import * as React from "react"
import { Box, Container, makeStyles, useMediaQuery } from "@material-ui/core"
import DigitalImprovementServices from "../digital-improvement-services/digital-improvement-services"
import HomeInfoBlockThree from "../home-info-blocks/home-info-block-three"
import ReferenceWorks from "../index-reference-works/reference-works"
import SendMessageBanner from "../send-message-banner/send-message-banner"
import TwoColumnInfo from "../two-column-info/two-column-info"
import TwoColumnTitle from "../two-column-info/two-column-title"
import TwoColumnText from "../two-column-info/two-column-text"
import IntroProgress from "./intro-progress"
import VerticalIntroProgress from "./vertical-intro-progress"

const useStyles = makeStyles((theme) => ({
  divDarkBg: {
    backgroundImage: `url("/images/wave_bg_5.jpg")`,
    transition: "background 5s linear",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "60px 0px"
  }
}))

const IntroBottom = () => {
  const classes = useStyles();
  const small = useMediaQuery('(max-width:1000px)');
  return <Box className={classes.divDarkBg}>
    <Container>
      <TwoColumnInfo
        leftColContent={<TwoColumnTitle firstPart={"Jövőkép kialakítása, szoftver programozása,"}
                                        secondPart={" üzleti célok elérése"}
                                        isSecondPartPrimaryColor color={"#fff"} />}
        rightColContent={<TwoColumnText
          content={<span>
            Az itteni innovatív csapat nagy hangsúlyt fektet arra, hogy ne csak annyit teljesítsen, amennyit Ön kér tőlünk.
            <br />
            <br />
            Nagy kaland vár Önökre, amelynek mi is szeretnénk részesei lenni. Így néz ki velünk dolgozni.
          </span>}
          color={"#fff"} />}
        backgroundColor={"rgba(200, 200, 200, 0.2)"}
        backdropFilter={"blur(40px)"}

      />
      <Box sx={{width: '100%', height: '50px'}}/>
      {small ? <VerticalIntroProgress/> : <IntroProgress/>}
      <Box sx={{width: '100%', height: '20px'}}/>
      <SendMessageBanner isPaper={false} />
    </Container>
  </Box>
}

export default IntroBottom
